/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyStoreCredit/Query/StoreCreditConfig/Query */
export class StoreCreditConfigQuery {
    getQuery() {
        return new Field('storeCreditConfig')
            .addFieldList(this.getStoreCreditConfigFields());
    }

    getStoreCreditConfigFields() {
        return [
            'isEnabled',
            'isRestrictProductsEnabled',
            'getRestrictAction',
            'getProductSkusForRestrict',
            'getCategoryIdsForRestrict',
            'isEncourage'
        ];
    }
}

export default new StoreCreditConfigQuery();
