/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { Fragment } from 'react';

import { ReactElement } from 'Type/Common.type';

import {
    MetaComponent as SourceMetaComponent
} from 'SourceComponent/Meta/Meta.component';

export class MetaComponent extends SourceMetaComponent {
    renderMeta(): ReactElement {
        const { metadata } = this.props;
        metadata.push({
            name: 'format-detection',
            content: 'telephone=no'
        });
        return [
            <Fragment key="meta-tags">
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name,
                        property = null,
                        content,
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
            </Fragment>,
        ];
    }
}

export default MetaComponent;
