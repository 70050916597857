/**
 * Amasty Shop By Brand compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { updateUrlRewrite } from 'Store/UrlRewrites/UrlRewrites.action';

import BrandsUrlResolverQuery from '../../query/BrandsUrlResolver.query';

const onSuccess = (args) => {
    const [{ brandUrlResolver, urlResolver }, dispatch, { urlParam }] = args;

    dispatch(
        updateUrlRewrite(
            brandUrlResolver || urlResolver || { notFound: true },
            urlParam
        )
    );
};

const prepareRequest = (args, callback, _instance) => [
    ...callback(...args),
    BrandsUrlResolverQuery.getQuery(window.location.pathname)
];

export default {
    'Store/UrlRewrites/Dispatcher': {
        'member-function': {
            onSuccess,
            prepareRequest
        }
    }
};

