/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

import { PAGE_SIZE } from '../component/AmastyDashboard/AmastyDashboard.config';
/** @namespace Scandiweb/AmastyStoreCredit/Query/AmastyDashboard/Query */

export class AmastyDashboardQuery {
    getQuery(customerId, currentPage = 1, pageSize = PAGE_SIZE) {
        return new Field('amastyDashboard')
            .addArgument('customerId', 'Int!', customerId)
            .addArgument('currentPage', 'Int', currentPage)
            .addArgument('pageSize', 'Int', pageSize)
            .addFieldList(this.getAmastyDashboardFields());
    }

    getAmastyDashboardFields() {
        return [
            'customerBalance',
            'customerId',
            'totalPages',
            this.getHistoryFields()
        ];
    }

    getHistoryFields() {
        return new Field('history')
            .addFieldList(this.getAmastyHistoryFields());
    }

    getAmastyHistoryFields() {
        return [
            'transactionId',
            'date',
            'change',
            'newBalance',
            'action',
            'comment'
        ];
    }
}

export default new AmastyDashboardQuery();
