/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const AMASTY_DASHBOARD_ROUTE = '/amstorecredit';
export const AMASTY_DASHBOARD = 'dashboard';
export const STORE_CREDIT_REFUNDS = 'store credit and refunds';
export const PAGE_SIZE = 10;
export const AMASTY_DASHBOARD_POSITION = 100;
