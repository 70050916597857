/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ChildrenType } from 'Type/Common.type';
import { getCartId } from 'Util/Cart';
import { prepareQuery } from 'Util/Query';
import { executeGet } from 'Util/Request/Request';
import { fetchQuery } from 'Util/Request/Query';

import AmastyCreditCheckoutQuery from '../query/AmastyCreditCheckout.query';
import AmastyCreditOrderQuery from '../query/AmastyCreditOrder.query';
import AmastyDashboardQuery from '../query/AmastyDashboard.query';
import GetCategoryByProductQuery from '../query/GetCategoryByProduct.query';

export const AmastyStoreCreditContext = createContext({
    amastyStoreCredit: {},
    setAmastyStoreCredit: () => {},
    currentAmount: 0,
    setCurrentAmount: () => {},
    storeCreditOrder: 0,
    setStoreCreditOrder: () => {},
    dashboard: {},
    setDashboard: () => {},
    categoryIds: [],
    setCategoryIds: () => {}
});

AmastyStoreCreditContext.displayName = 'AmastyStoreContext';

/** @namespace Scandiweb/AmastyStoreCredit/Context/AmastyStoreCredit/AmastyStoreCreditProvider */
export const AmastyStoreCreditProvider = ({ children }) => {
    const [amastyStoreCredit, setAmastyStoreCredit] = useState({});
    const [currentAmount, setCurrentAmount] = useState(0);
    const [storeCreditOrder, setStoreCreditOrder] = useState(0);
    const [dashboard, setDashboard] = useState({
        customerBalance: '',
        history: [],
        totalPages: 0
    });

    const [categoryIds, setCategoryIds] = useState([]);

    const grandTotal = useSelector((state) => state.CartReducer?.cartTotals?.prices?.grand_total);
    const cartItems = useSelector((state) => state.CartReducer?.cartTotals?.items);

    const fetchAmastyStoreCredit = async () => {
        const query = AmastyCreditCheckoutQuery.getQuery();

        const {
            storeCreditCheckout: { amastyStoreCredit }
        } = await executeGet(prepareQuery(query), 'AmastyStoreCreditCheckout', 1);

        setAmastyStoreCredit(amastyStoreCredit);
    };

    const fetchStoreCreditOrder = async (orderId) => {
        const {
            storeCreditOrder: {
                orderStoreCreditAmount
            }
        } = await fetchQuery(AmastyCreditOrderQuery.getQuery(orderId));

        setStoreCreditOrder(orderStoreCreditAmount);
    };

    const fetchDashboard = async (customerId, currentPage) => {
        const {
            amastyDashboard
        } = await fetchQuery(AmastyDashboardQuery.getQuery(customerId, currentPage));

        setDashboard(amastyDashboard);
    };

    const fetchCategoryIdsForRestrict = async () => {
        const {
            categoryByProduct: {
                categoryIds: catIds
            }
        } = await fetchQuery(GetCategoryByProductQuery.getQuery(getCartId()));

        setCategoryIds(catIds);
    };

    useEffect(() => {
        fetchCategoryIdsForRestrict();
    }, [cartItems]);

    useEffect(() => {
        fetchAmastyStoreCredit();
    }, [grandTotal]);

    const value = {
        amastyStoreCredit,
        fetchAmastyStoreCredit,
        currentAmount,
        setCurrentAmount,
        fetchStoreCreditOrder,
        storeCreditOrder,
        fetchDashboard,
        dashboard,
        categoryIds
    };

    return (
        <AmastyStoreCreditContext.Provider value={ value }>
            { children }
        </AmastyStoreCreditContext.Provider>
    );
};

AmastyStoreCreditProvider.displayName = 'AmastyStoreCreditProvider';

AmastyStoreCreditProvider.propTypes = {
    children: ChildrenType.isRequired
};
