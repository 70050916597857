/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyStoreCredit/Query/GetCategoryByProduct/Query */
export class GetCategoryByProductQuery {
    getQuery(cartId) {
        return new Field('categoryByProduct')
            .addArgument('cartId', 'String!', cartId)
            .addField('categoryIds');
    }
}

export default new GetCategoryByProductQuery();
