import {AnyAction} from "redux";
import {RewardPoints, CustomerRewardPoints, CustomerRewardPointsTransactions} from "Query/RewardPoints.type";

export type RewardPointsAction = UpdateRewardPointsAction
    | UpdateCustomerRewardPointsAction
    | UpdateCustomerRewardPointsTransactionsAction
    | SetLoadingStatusAction;

export enum RewardPointsActionType {
    SET_LOADING_STATUS = 'SET_LOADING_STATUS',
    UPDATE_REWARD_POINTS = 'UPDATE_REWARD_POINTS',
    UPDATE_CUSTOMER_REWARD_POINTS = 'UPDATE_CUSTOMER_REWARD_POINTS',
    UPDATE_CUSTOMER_REWARD_POINTS_TRANSACTIONS = 'UPDATE_CUSTOMER_REWARD_POINTS_TRANSACTIONS',
}

export interface UpdateRewardPointsAction extends AnyAction {
    type: RewardPointsActionType.UPDATE_REWARD_POINTS;
    rewardPointsData: RewardPoints;
}

export interface UpdateCustomerRewardPointsAction extends AnyAction {
    type: RewardPointsActionType.UPDATE_CUSTOMER_REWARD_POINTS;
    customerRewardPointsData: CustomerRewardPoints;
}

export interface UpdateCustomerRewardPointsTransactionsAction extends AnyAction {
    type: RewardPointsActionType.UPDATE_CUSTOMER_REWARD_POINTS_TRANSACTIONS;
    rewardPointsTransactions: CustomerRewardPointsTransactions;
}

export interface SetLoadingStatusAction extends AnyAction {
    type: RewardPointsActionType.SET_LOADING_STATUS;
    status?: boolean;
}


declare module 'Util/Store/Store.type' {
    export interface RootState {
        RewardPointsReducer: RewardPointsStore;
    }
}

export interface RewardPointsStore {
    status: boolean;
    rewardPointsData: RewardPoints;
    customerRewardPointsData: CustomerRewardPoints;
    rewardPointsTransactions: CustomerRewardPointsTransactions;
}
