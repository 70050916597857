/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { withStoreRegex } from 'SourceComponent/Router/Router.component';
import { RouterItemType } from 'SourceComponent/Router/Router.config';

import {
    AMASTY_DASHBOARD,
    AMASTY_DASHBOARD_POSITION,
    AMASTY_DASHBOARD_ROUTE,
    STORE_CREDIT_REFUNDS
} from '../../component/AmastyDashboard/AmastyDashboard.config';

export const MyAccount = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "account" */
    'Route/MyAccount'
));

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-jsx-variables
export const addRoutesToSwitchItems = (member) => ([
    ...member,
    {
        component: (
             <Route
               path={ withStoreRegex(AMASTY_DASHBOARD_ROUTE) }
               // eslint-disable-next-line react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
               render={ (props) => <MyAccount { ...props } selectedTab={ STORE_CREDIT_REFUNDS } /> }
             />
        ),
        position: AMASTY_DASHBOARD_POSITION,
        name: AMASTY_DASHBOARD
    }
]);

export default {
    'Component/Router/Component': {
        'member-property': {
            [RouterItemType.SWITCH_ITEMS_TYPE]: addRoutesToSwitchItems
        }
    }
};
