/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { AmastyStoreCreditProvider } from '../../context/AmastyStoreCredit';

const addAmastyStoreCreditProvider = (member) => [
    (children) => (
        <AmastyStoreCreditProvider>
            { children }
        </AmastyStoreCreditProvider>
    ),
    ...member
];

export default {
    'Component/App/Component': {
        'member-property': {
            contextProviders: addAmastyStoreCreditProvider
        }
    }
};
