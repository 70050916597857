import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { updateConfigDevice } from 'Store/Config/Config.action';
import { updateMeta } from 'Store/Meta/Meta.action';
import { setBigOfflineNotice } from 'Store/Offline/Offline.action';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

import {
    RouterContainerMapDispatchProps,
} from 'SourceComponent/Router/Router.type';

import {
    mapStateToProps,
    RouterContainer as SourceRouterContainer,
} from 'SourceComponent/Router/Router.container';

export {
    mapStateToProps,
};
export const mapDispatchToProps = (dispatch: Dispatch): RouterContainerMapDispatchProps => ({
    updateMeta: (meta) => dispatch(updateMeta(meta)),
    updateConfigDevice: (device) => dispatch(updateConfigDevice(device)),
    setBigOfflineNotice: (isBig) => dispatch(setBigOfflineNotice(isBig)),
    init: () => waitForPriorityLoad().then(
        /** @namespace Component/Router/Container/mapDispatchToProps/waitForPriorityLoad/then */
        () => {
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Config/Config.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.handleData(dispatch, undefined));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Wishlist/Wishlist.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/MyAccount/MyAccount.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.handleCustomerDataOnInit(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/Cart/Cart.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/ProductCompare/ProductCompare.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.updateInitialProductCompareData(dispatch));
            import(
                /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
                'Store/RewardPoints/RewardPoints.dispatcher'
                ).then(({ default: dispatcher }) => dispatcher.updateInitialRewardPointsData(dispatch));
        },
    ),
});
/** @namespace VusaApp/Component/ProductListWidget/Container */
export class RouterContainer extends SourceRouterContainer {

}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
