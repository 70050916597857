import {
    RouterItemType,
    RouterBeforeItemType,
    RouterSwitchItemType,
    RouterAfterItemType,
    URL_ONLY_MAIN_ITEMS_RENDER,
    DEMO_NOTICE_HEIGHT
} from "SourceComponent/Router/Router.config";

export enum RouterSwitchItemTypeExtend {
    MY_ACCOUNT_REWARD_POINTS = 'MY_ACCOUNT_REWARD_POINTS',
    MY_ACCOUNT_STORE_CREDIT_REFUNDS = 'MY_ACCOUNT_STORE_CREDIT_REFUNDS',
}

export {
    RouterItemType,
    RouterBeforeItemType,
    RouterSwitchItemType,
    RouterAfterItemType,
    URL_ONLY_MAIN_ITEMS_RENDER,
    DEMO_NOTICE_HEIGHT
}
