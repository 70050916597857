/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyStoreCredit/Query/AmastyCreditCheckout/Query */
export class AmastyCreditCheckoutQuery {
    getQuery() {
        return new Field('storeCreditCheckout')
            .addField(this.getAmastyCreditCheckoutFields());
    }

    getAmastyCreditCheckoutFields() {
        return new Field('amastyStoreCredit')
            .addFieldList(this.getAmastyCreditCheckoutConfigFields());
    }

    getAmastyCreditCheckoutConfigFields() {
        return [
            'amStoreCreditAmountAvailable',
            'encourage'
        ];
    }
}

export default new AmastyCreditCheckoutQuery();
