/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Reducer } from 'redux';

import BrowserDatabase from 'Util/BrowserDatabase';

import {
    RewardPointsAction,
    RewardPointsActionType,
    RewardPointsStore,
    UpdateRewardPointsAction,
    UpdateCustomerRewardPointsAction, UpdateCustomerRewardPointsTransactionsAction, SetLoadingStatusAction,
} from './RewardPoints.type';

import {
    CustomerRewardPoints, CustomerRewardPointsTransactions,
    RewardPoints
} from 'Query/RewardPoints.type';
export const REWARD_POINTS = 'reward_points';
export const CUSTOMER_REWARD_POINTS = 'customer_reward_points';

/** @namespace Store/RewardPoints/Reducer/updateRewardPoints */
export const updateRewardPoints = (action: UpdateRewardPointsAction, state: RewardPointsStore): RewardPointsStore => {
    const { rewardPointsData } = action;

    BrowserDatabase.setItem(
        rewardPointsData,
        REWARD_POINTS,
    );

    return {
        ...state,
        rewardPointsData,
    };
};

export const updateCustomerRewardPoints = (action: UpdateCustomerRewardPointsAction, state: RewardPointsStore): RewardPointsStore => {
    const { customerRewardPointsData } = action;

    BrowserDatabase.setItem(
        customerRewardPointsData,
        CUSTOMER_REWARD_POINTS,
    );

    return {
        ...state,
        customerRewardPointsData,
    };
};

export const updateCustomerRewardPointsTransactions = (action: UpdateCustomerRewardPointsTransactionsAction, state: RewardPointsStore): RewardPointsStore => {
    const { rewardPointsTransactions } = action;

    return {
        ...state,
        rewardPointsTransactions,
    };
}

export const updateLoadingStatus = (action: SetLoadingStatusAction, state: RewardPointsStore): RewardPointsStore => {
    const { status = false } = action;

    return {
        ...state,
        status,
    };
}

/** @namespace Store/RewardPoints/Reducer/getInitialState */
export const getInitialState = (): RewardPointsStore => ({
    status: true,
    rewardPointsData: BrowserDatabase.getItem(REWARD_POINTS) || {} as RewardPoints,
    customerRewardPointsData: BrowserDatabase.getItem(CUSTOMER_REWARD_POINTS) || {} as CustomerRewardPoints,
    rewardPointsTransactions: {} as CustomerRewardPointsTransactions,
});

/** @namespace Store/RewardPoints/Reducer/RewardPointsReducer */
export const RewardPointsReducer: Reducer<RewardPointsStore, RewardPointsAction> = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
        case RewardPointsActionType.UPDATE_REWARD_POINTS:
            return updateRewardPoints(action, state);
        case RewardPointsActionType.UPDATE_CUSTOMER_REWARD_POINTS:
            return updateCustomerRewardPoints(action, state);
        case RewardPointsActionType.UPDATE_CUSTOMER_REWARD_POINTS_TRANSACTIONS:
            return updateCustomerRewardPointsTransactions(action, state);
        case RewardPointsActionType.SET_LOADING_STATUS:
            return updateLoadingStatus(action, state);
        default:
            return state;
    }
};

export default RewardPointsReducer;
