/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/AmastyStoreCredit/Query/AmastyCreditOrder/Query */
export class AmastyCreditOrderQuery {
    getQuery(orderId) {
        return new Field('storeCreditOrder')
            .addArgument('orderId', 'Int', orderId)
            .addField('orderStoreCreditAmount');
    }
}

export default new AmastyCreditOrderQuery();
