/**
 * Amasty Store Credit compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { UPDATE_CONFIG } from 'SourceStore/Config/Config.action';

import StoreCreditConfigQuery from '../../query/StoreCreditConfig.query';

export const addStoreCreditConfigToInitialState = (args, callback) => ({
    ...callback(...args),
    storeCreditConfig: {}
});

export const getStoreCreditConfigFromAction = (args, callback) => {
    const [, action] = args;

    const {
        type,
        config: {
            storeCreditConfig
        } = {}
    } = action;

    if (type !== UPDATE_CONFIG) {
        return callback(...args);
    }

    return { ...callback(...args), storeCreditConfig };
};

export const extendConfigQueryWithStoreCreditConfig = (args, callback) => ([
    ...callback(...args),
    StoreCreditConfigQuery.getQuery()
]);

export default {
    'Store/Config/Reducer/getInitialState': {
        function: addStoreCreditConfigToInitialState
    },
    'Store/Config/Reducer/ConfigReducer': {
        function: getStoreCreditConfigFromAction
    },
    'Store/Config/Dispatcher': {
        'member-function': {
            prepareRequest: extendConfigQueryWithStoreCreditConfig
        }
    }
};
